window.contentLoaded(window, function (e) {
    //Add your project specific scripts here
});

// Get Current Month Name
function getCurrentMonth() {
    var months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    var now = new Date();
    var thisMonth = months[now.getMonth()]; // getMonth method returns the month of the date (0-January :: 11-December)

    document.querySelectorAll('.output').forEach(el => el.innerText = thisMonth)
}

getCurrentMonth();