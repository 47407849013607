document.addEventListener('DOMContentLoaded', function() {
    var user = 'info';
    var domain = 'marvixdigital.com';
    var phoneNumber = '+38651231723';
    
    // Function to format the phone number according to the pattern +xxx xx xxx xxx
    function formatPhoneNumber(number) {
        // Remove the "+" sign for processing
        number = number.substring(1);
        var countryCode = number.substring(0,3);
        var partOne = number.substring(3,5);
        var partTwo = number.substring(5,8);
        var partThree = number.substring(8,12);
        return '+' + countryCode + ' ' + partOne + ' ' + partTwo + ' ' + partThree;
    }

    var elements = document.querySelectorAll('.contact-item'); // Find all list items with the contact-item class
    
    elements.forEach(function(el) {
        var contactType = el.getAttribute('data-contact-type'); // Get the contact type from each element
        
        if(contactType === 'email') {
            // Create a new anchor element for the email
            var emailLink = document.createElement('a');
            emailLink.href = 'mailto:' + user + '@' + domain;
            emailLink.textContent = user + '@' + domain;
            el.innerHTML = 'Email: '; // Clear the existing content
            el.appendChild(emailLink);
        } else if(contactType === 'phone') {
            // Format the phone number
            var formattedPhone = formatPhoneNumber(phoneNumber);
            // Create a new anchor element for the phone
            var phoneLink = document.createElement('a');
            phoneLink.href = 'tel:' + formattedPhone.replace(/\s+/g, ''); // Remove spaces for the href
            phoneLink.textContent = formattedPhone;
            el.innerHTML = 'Phone: '; // Clear the existing content
            el.appendChild(phoneLink);
        }
    });

    //Header phone protection
    var phoneLink = document.querySelector('.header-phone');
    var phoneText = phoneLink.querySelector('.phone-text em');

    // Format the phone number
    var formattedPhone = formatPhoneNumber(phoneNumber);

    // Update the href attribute of the <a> tag and the text content of the <em> tag
    phoneLink.href = 'tel:' + phoneNumber.replace(/\s/g, ''); // Ensure href format is without spaces
    phoneText.textContent = formattedPhone;
});