window.contentLoaded(window, function (e) {
	window.addEventListener("scroll", () => {
		window.scrollY >= 50
			? document.querySelector("header").classList.add("scrolled")
			: document.querySelector("header").classList.remove("scrolled");
		window.scrollY >= 50
			? $('.header-logo img').attr('src','/static/images/marvix-digital-logo.svg')
			: $('.header-logo img').attr('src','/static/images/marvix-digital-logo-white.svg');
	});
});
