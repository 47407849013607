class LazyLoad {
    constructor() {
        this.settings = {
            src: 'data-src',
            srcset: 'data-srcset',
            sizes: 'data-sizes',
            selector: '.lazyload',
            root: null,
            rootMargin: '0px',
            threshold: 0
        };
        this.images = [];
    }

    init() {
        this.images = document.querySelectorAll(this.settings.selector);
        const observerConfig = {
            root: this.settings.root,
            rootMargin: this.settings.rootMargin,
            threshold: [this.settings.threshold],
        };

        this.observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.loadImage(entry.target);
                    this.observer.unobserve(entry.target);
                }
            });
        }, observerConfig);

        this.images.forEach(image => this.observer.observe(image));
    }

    loadImage(element) {
        const src = element.getAttribute(this.settings.src);
        const sizes = element.getAttribute(this.settings.sizes);

        if (element.tagName.toLowerCase() === 'img' && src) {
            element.src = src; // Sets the original image source

            if (sizes === 'auto') {
                // If "auto" sizes are specified, generate and set srcset
                element.setAttribute('srcset', this.generateSrcset(src));
            }
            
            // Mark the element as having been lazy loaded
            element.setAttribute('data-img-lazyloaded', 'true');
        } else if (src) {
            // For non-<img> elements (e.g., background images), set the background image
            element.style.backgroundImage = `url('${src}')`;
            // Similarly, mark these elements as loaded
            element.setAttribute('data-img-lazyloaded', 'true');
        }
    }

    generateSrcset(src) {
        const srcsetSizes = [300, 768, 1024, 1200, 1536, 1920, 2048];
        return srcsetSizes.map(size => `${src.replace(/\.\w+$/, `-${size}w$&`)} ${size}w`).join(', ');
    }

    loadAndDestroy() {
        if (!this.settings) return;
        this.loadImages();
        this.destroy();
    }

    loadImages() {
        if (!this.settings) return;
        this.images.forEach(image => this.loadImage(image));
    }

    destroy() {
        if (!this.settings) return;
        this.observer.disconnect();
        this.settings = null;
        this.images = [];
    }
}

export default function images() {
    if (document.querySelector('.lazyload')) {
        const lazy = new LazyLoad();
        lazy.init();
    }
}

document.addEventListener('DOMContentLoaded', () => {
    images();
});
