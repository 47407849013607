document.addEventListener('DOMContentLoaded', (event) => {
    const ratingContainer = document.querySelector('.star-rating');
  
    if (ratingContainer) {
        document.querySelectorAll('.star-rating .star').forEach(item => {
            item.addEventListener('click', (e) => {
                let value = e.target.dataset.value;
                document.getElementById('rating').value = value; // Set the rating value
                e.target.parentNode.querySelectorAll('.star').forEach(star => {
                    star.style.color = star.dataset.value <= value ? 'gold' : '#ccc';
                });
                e.target.parentNode.querySelector('.rating-value').textContent = `Rating: ${value}`;
            });

            item.addEventListener('mouseover', (e) => {
                let value = e.target.dataset.value;
                e.target.parentNode.querySelectorAll('.star').forEach(star => {
                    star.style.color = star.dataset.value <= value ? 'gold' : '#ccc';
                });
                e.target.parentNode.querySelector('.rating-value').textContent = `Rating: ${value}`;
            });

            item.addEventListener('mouseout', (e) => {
                let ratingValue = document.getElementById('rating').value;
                e.target.parentNode.querySelectorAll('.star').forEach(star => {
                    star.style.color = star.dataset.value <= ratingValue ? 'gold' : '#ccc';
                });
                if (ratingValue) {
                    e.target.parentNode.querySelector('.rating-value').textContent = `Rating: ${ratingValue}`;
                } else {
                    e.target.parentNode.querySelector('.rating-value').textContent = '';
                }
            });
        });
    }
});
